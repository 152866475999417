export const apiUrl = process.env.NEXT_PUBLIC_API_URI;
const serverIp = "https://catch22.website"//"https://piroshkybakery.com"//"https://catch22.website"//"https://piroshkybakery.com"//"https://piroshkybakery.com"//"https://catch22.website";//"https://piroshkybakery.com"//"https://catch22.website";//"https://piroshkybakery.com"//"https://catch22.website";//"localhost:8080/api/v1"//"https://catch22.website";//"localhost:8080/api/v1"//"https://piroshkybakery.com"//"https://catch22.website";//"https://piroshkybakery.com"//"https://catch22.website";//"https://piroshkybakery.com";//"https://piroshky.online";//"https://piroshkybakery.com";//"localhost:8080/api/v1";//"https://piroshkybakery.com";//"localhost:8080/api/v1";//"https://piroshkybakery.com";//"localhost:8080/api/v1";//"https://piroshkybakery.com";//"localhost:8080/api/v1";//"https://piroshkybakery.com";//"localhost:8080/api/v1";//"https://piroshkybakery.com";//"https://piroshkybakery.com";//"localhost:8080/api/v1";//"https://piroshky.online";//"https://piroshkybakery.com"; //"https://piroshky.store"//"localhost:8080/api/v1"//"https://catch22market.com"//"localhost:8080/api/v1"//"https://catch22market.com"//"localhost:8080/api/v1"//"https://catch22market.com"//"localhost:8080/api/v1"//"https://catch22market.com"
export const apiPath = `${
  serverIp.startsWith("http") ? serverIp : "http://" + serverIp
}${serverIp.startsWith("http") ? "/api" : ""}`;
export const websiteDomain = "https://piroshkybakery.com";

export const mediaPath = `${
  serverIp.startsWith("http")
    ? serverIp + "/media/"
    : "http://" + serverIp.replace("/api/v1", "/mediafiles/")
}`;
const socketIp = "localhost";
export const socketPath = `ws://${socketIp}:6030`;

export const blogPath = "https://piroshky.store";

export const ecommerceStoreGroupId = "aaaf7101-a8f8-4f91-98a6-6bed3313866e"; //"2ff37240-6620-4c27-a816-fa0d11cefa6d"//"aaaf7101-a8f8-4f91-98a6-6bed3313866e"//"4e1d10b6-0ddd-426c-a396-c4ddaa4824d8"//"aaaf7101-a8f8-4f91-98a6-6bed3313866e"//"4e1d10b6-0ddd-426c-a396-c4ddaa4824d8"//"aaaf7101-a8f8-4f91-98a6-6bed3313866e"//
export const ecommerceStoreId = "057ef61d-45a7-4bcf-9166-2f64201da7b2"; //"3d7d366a-1005-434d-a276-47022d98cb72"//"057ef61d-45a7-4bcf-9166-2f64201da7b2"//"174100f0-13a3-48db-bdae-d8c5d9694982"//"057ef61d-45a7-4bcf-9166-2f64201da7b2"//"174100f0-13a3-48db-bdae-d8c5d9694982"//"057ef61d-45a7-4bcf-9166-2f64201da7b2"//"174100f0-13a3-48db-bdae-d8c5d9694982"
export const blogEcommerceStoreGroupId = "aaaf7101-a8f8-4f91-98a6-6bed3313866e";
export const blogEcommerceStoreId = "41db3b93-5f27-446d-8d50-b815b5a66152";

export const ssrMode = typeof window === "undefined";

export const recaptchaKey = "6Ld6nPEjAAAAAMtHFKNgPZUTJG9IMOueDAU26kYr"

// ecommerceStoreGroupId aaaf7101-a8f8-4f91-98a6-6bed3313866e
// ecommerceStoreId 057ef61d-45a7-4bcf-9166-2f64201da7b2
//new

export const faviconDefault = "/favicon.ico";

export const META_DEFAULTS = {
  custom: [],
  description: "Default description",
  image: "",
  title: "Piroshky Piroshky Bakery",
  type: "website",
  url: websiteDomain,
};
